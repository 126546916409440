import React from 'react'
import { chunk } from 'lodash'
import { Link } from 'gatsby'

import { AwesomeButton } from 'react-awesome-button'
import '@fortawesome/fontawesome-free/css/all.css'
import 'react-awesome-button/dist/styles.css'

import Layout from '../components/Layout'
import Head from '../components/Head'
import { commonContents } from '../contents'
import { MenuButtons } from '../components/HomePageMenuAnimator'
import './styles.scss'

const HomePage = () => {
  return (
    <Layout isNavVisible={false} noActive={true}>
      <Head title="" />
      <div id="homePage" className="row flex-grow-1">
        <div className="self-info col-12 col-lg-6">
          <div className="animate fade-in content">
            <h1>Looking for Kim?</h1>
            <p>
              I'm a full-stack web developer currently focused in modern
              JavaScript. I design and code stunning websites and applications,
              and I love what I do. You can learn more{' '}
              <Link to="/me">about me</Link>, view my{' '}
              <Link to="/portfolio">portfolio</Link> or simply{' '}
              <Link to="/contact">reach me</Link> if you need any help in your
              project.
            </p>
            <p>
              You can also reach me at{' '}
              <a href="mailto:hello@thekimlopez.com">hello@thekimlopez.com</a>
            </p>
            <Link to="/portfolio">
              <AwesomeButton
                style={{ fontWeight: 500 }}
                type="secondary"
                ripple
              >
                View Portfolio
              </AwesomeButton>
            </Link>
          </div>
        </div>

        <MenuButtons menuButtons={chunk(commonContents.pages, 2)} />
      </div>
    </Layout>
  )
}

export default HomePage
