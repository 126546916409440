import React, { useRef, useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { AwesomeButton } from 'react-awesome-button'
import 'react-awesome-button/dist/styles.css'

const setStyle = (elem, styles) => {
  Object.keys(styles).forEach(name => {
    elem.style[name] = styles[name]
  })
}

const getTopMenuPos = ({ name }) => {
  const topMenu = document.querySelector(`.top-menu-${name}-container`)

  const { x, top, width, height } = topMenu.getBoundingClientRect()
  return {
    right: `${Math.abs(window.innerWidth - (x + width))}px`,
    top: `${Math.abs(top)}px`,
    width: `${Math.abs(width)}px`,
    height: `${Math.abs(height)}px`
  }
}

const startFlying = clickedButton => {
  const firstClickedButton = document.querySelector(`.${clickedButton}`)
  const homeMenuButtons = Array.from(
    document.querySelectorAll('.menu-buttons .menu-item')
  ).sort((a, b) =>
    a === firstClickedButton ? -1 : b === firstClickedButton ? 1 : 0
  )
  const lastMenuButton = homeMenuButtons[homeMenuButtons.length - 1]
  const content = document.querySelector('.self-info > .content')

  firstClickedButton.addEventListener('transitionend', e => {
    const nextPage = clickedButton.replace('homebtn-', '')
    const topMenuNextLink = document.querySelector(
      `.top-menu-${nextPage}-container > a`
    )

    topMenuNextLink.classList.add('active')
  })

  content.addEventListener('animationend', e => {
    if (e.animationName === 'zoomOutLeft') {
      const nextPage = clickedButton.replace('homebtn-', '/')
      nextPage === '/home' ? navigate('/') : navigate(nextPage)
    }
  })

  lastMenuButton.addEventListener('transitionend', e => {
    if (e.propertyName === 'right') {
      document.querySelectorAll('.top-menu').forEach(menu => {
        menu.style.opacity = '1'
      })
      homeMenuButtons.forEach(menu => {
        menu.style.display = 'none'
      })
      content.classList.add('zoom-out-left')
    }
  })

  let i = 0
  const changeMenuBtnPos = () => {
    setTimeout(() => {
      if (i < homeMenuButtons.length) {
        const button = homeMenuButtons[i]
        const { left, width, top } = button.getBoundingClientRect()

        const buttonTarget = getTopMenuPos({
          name: button.textContent.toLowerCase(),
          icon: button.querySelector('i'),
          textName: button.querySelector('.menu-name'),
          right: window.innerWidth - (left + width),
          top
        })

        // Animate button to target position on top menu
        setStyle(button, {
          transition: 'all 1.5s ease-in-out',
          ...buttonTarget,
          padding: 0
        })

        // Hide icon and show text while animating
        button.classList.add('is-animating')

        i++

        changeMenuBtnPos()
      }
    }, 300)
  }

  changeMenuBtnPos()
}

const MenuButton = ({ iconName, name, withAction }) => {
  const buttonId = `homebtn-${name.toLowerCase()}`
  const menuButton = useRef(null)
  const [buttonPosition, setButtonPosition] = useState({ right: '', top: '' })

  useEffect(() => {
    const { right, top } = menuButton.current.container.getBoundingClientRect()

    setButtonPosition({
      top,
      position: 'fixed',
      right: window.innerWidth - right
    })
  }, [buttonPosition.right])

  return (
    <AwesomeButton
      onPress={e => (withAction ? startFlying(buttonId) : '')}
      className={`${buttonId} menu-item`}
      type="primary"
      style={buttonPosition}
      ref={menuButton}
      ripple
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <i className={`fa ${iconName}`}></i>
        <span className="menu-name">{name}</span>
      </div>
    </AwesomeButton>
  )
}

export const MenuButtons = ({ menuButtons }) => {
  return (
    <div
      id="menuButtons"
      className="animate fade-in menu-buttons col-lg-6 d-none d-lg-flex flex-column justify-content-center"
    >
      {menuButtons.map((buttons, i) => (
        <div className="d-flex justify-content-end" key={i}>
          {buttons.map(({ iconName, name, withAction = true }, i) => (
            <MenuButton
              key={i}
              iconName={iconName}
              name={name}
              withAction={withAction}
            />
          ))}
        </div>
      ))}
    </div>
  )
}
